.left-sidebar {
  max-height: calc(100vh - $headerHeight - $footerHeight - 12rem);
  height: calc(100vh - $headerHeight - $footerHeight - 12rem);
  background: #eaeaee;
  @apply flex flex-col items-center p-4 bg-white overflow-y-auto;
  color: $primaryBlue;
  background-color: #eaeaee;

  h1 {
    @apply text-center;
    font-family: 'Futura-Medium';
    font-size: 25px;
  }

  &__qr {
    font-family: 'Futura-Bold';
    @apply flex flex-col flex-1 px-5 text-center text-4xl justify-center  py-10;
    padding: 0;

    p {
      font-size: 1.8rem;
    }

    img {
      @apply px-5;
    }
  }
}
.qr-wrap {
  padding: 1rem 1rem 1.5rem !important;
}
.qr-wrap svg {
  max-width: 70% !important;
  margin: 0 auto;
}
p.abbreviations {
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Futura-Medium';
    line-height: 1.4rem;
    text-align: left;
    margin-top: 1rem;
}