.leaderboard {
  @apply w-full flex flex-col items-center  overflow-y-auto shadow-2xl;
  max-height: calc(100vh - $headerHeight - $footerHeight - 12rem);
  height: calc(100vh - $headerHeight - $footerHeight - 12rem);
  background: #eaeaee;
  background-color: #eaeaee;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  h1 {
    font-family: 'Futura-Bold';
    color: $primaryBlue;
    font-size: 2rem;
    @apply self-start;
    margin-bottom: 15px;
  }

  &__places {
    @apply flex capitalize py-4 rounded-lg w-full px-4;
  }

  &__item {
    @apply flex items-center flex-1;
  }

  &__item-content {
    @apply flex flex-col items-center flex-1 text-center pl-3;
  }

  &__items {
    color: $primaryBlue;
    @apply flex flex-col flex-1 gap-2 text-lg w-full py-5 px-6 items-center;
  }

  &__active {
    background: $primaryBlue;
    color: $yellow;
  }
}
.rank {
    --tw-border-opacity: 1;
    --tw-text-opacity: 1;
    align-items: center;
    border-color: rgb(156 163 175/var(--tw-border-opacity));
    border-radius: 9999px;
    border-width: 2px;
    color: rgb(156 163 175/var(--tw-text-opacity));
    display: flex;
    font-family: Futura-Bold;
    font-size: 1.875rem;
    height: 2.75rem;
    justify-content: center;
    line-height: 2.25rem;
    width: 2.75rem;
}

.user-rank {
  @apply flex items-center gap-2 flex-1;

  &__throphy {
    display: flex;
    flex: 1 1;
    align-items: center;
    gap: .5rem;
  }

  &__rank {
    font-family: 'Futura-Bold';
    @apply rounded-full w-11 h-11 flex items-center justify-center text-3xl text-gray-400;
  
    &.active {
      color: $white;
      @apply border-white;
    }
  }
}
.user-rank img {
    max-width: 25px;
}
.leaderboard__places {
  background-color: #ffffff;
  margin-bottom: 10px;
  min-height: 80px;
}
.leaderboard__places.leaderboard__active {
    background-color: #001f5b;
    color: #ffc647;
}