.main-input {
  @apply flex flex-col w-full relative;
  margin-bottom: 20px;

  &__error {
    @apply pt-2;
    color: #ffffff;
  }

  &__kb {
    @apply text-black absolute left-0 right-0 z-10;
  }

  &__kb_bottom {
    top: 5rem;
  }

  &__kb_top {
    top: -12rem;
  }

  &__phone {
    width: 100%;
    padding-left: 3rem !important;
  }
}
.main-input__kb {
  display: none;
}