.header {
  height: $headerHeight;
  font-family: 'Futura-Book-Regular';
  color: $primaryBlue;
  box-shadow: 0px 20px 20px -5px rgb(0 0 0 / 20%);
  background: $gray;
  @apply flex gap-28 w-full items-center pl-8 pr-36 text-lg capitalize overflow-hidden z-30 justify-center;

  &__contact {
    background: $yellow;
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border: 2px solid $primaryBlue;
    @apply py-1 px-5 shadow-lg ml-auto;
  }

  & > p {
    @apply cursor-pointer min-w-min;
    padding: 0 4%;
  }

  &__opko {
    min-width: 192px;
    color: $secondaryBlue;
    @apply text-sm ml-auto;

    & img {
      @apply w-48;
    }
  }
}


