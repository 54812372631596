@keyframes spinn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 50px;
  height: 50px;
  border: 10px solid $primaryBlue; 
  border-top: 10px solid $yellow; 
  border-radius: 50%;
  animation: spinn 1.5s linear infinite;

  &__inverse {
    border: 10px solid $yellow;
    border-top: 10px solid $primaryBlue;
  }
}
