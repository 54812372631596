.categories {
  @apply w-full flex-1 flex flex-col;

  &__empty {
    @apply text-5xl text-white text-center;
  }
}

.info-header {
  @apply flex mb-6 h-16;

  &__stats {
    color: $yellow;
    background: $primaryPink;
    min-width: 100px;
    font-family: 'Futura-Medium';
    @apply rounded-xl pl-1 pr-2 text-lg whitespace-nowrap;

    span {
      @apply text-white ml-1 text-lg;
      font-family: 'Futura-Bold';
    }

    p {
      @apply text-2xl;
    }
  }

  &__name {
    background: $primaryBlue;
    font-family: 'Futura-Bold';
    max-width: 300px;
    @apply text-white rounded-xl py-2 px-6 capitalize;
  }

  &__progress {
    @apply text-xl flex flex-col justify-center  flex-1 gap-1;
    font-family: 'Futura-Bold';
    color: $white;

    &_bar {
      background: #f9d2f4;
      @apply h-4 w-56  relative rounded-xl;
    }

    &_pass {
      background: $primaryPink;
      @apply absolute h-4 transition-all rounded-xl;
    }
  }

  &__points {
    @apply  flex items-center gap-1;
  }

  &__rank {
    @apply flex gap-1 text-white;
  }
}

.category {
  box-shadow: 6px 0 15px 5px rgb(0 0 0 / 20%);
  background-color: #ffffff;
  @apply border border-gray-400 flex-1 z-30 px-8 py-8 flex  justify-center;

  h1,
  h2 {
    text-align: center;
  }

  h2 {
    font-family: 'Futura-Book-Regular';
    @apply text-4xl;
    color: $secondaryBlue;
  }

  h1 {
    @apply text-3xl mb-1;
    font-family: 'Futura-Bold';
    color: $primaryPink;
  }

  &__done {
    @apply flex flex-col text-2xl px-8 items-center;
    h1 {
      font-family: 'Futura-Bold';
      @apply text-black;
    }

    h4 {
      @apply pt-6 text-lg;
    }
  }

  &__desc {
    font-size: 2rem;
    color: #011e5b;
    font-family: 'Futura-Bold';
    @apply mt-4;
  }
}

.wrapper-category {
  overflow-y: auto;
  max-height: calc(100vh - $headerHeight - $footerHeight - 7rem);
  height: calc(100vh - $headerHeight - $footerHeight - 7rem);
  background: linear-gradient(0deg, rgba(204, 39, 176, 1) 0%, rgba(255, 198, 71, 1) 60%);
  @apply px-2 flex flex-col;

  &__title {
    @apply text-3xl mb-1 flex gap-2 px-16 py-4;
    color: $primaryBlue;

    h1 {
      font-family: 'Futura-Bold';
      @apply whitespace-nowrap;
    }
  }
}

.done-category {
  @apply flex flex-col text-2xl px-8 items-center;

  h1 {
    font-family: 'Futura-Bold';
    @apply text-black;
  }

  h4 {
    @apply pt-6 text-lg;
  }

  &__results {
    @apply flex items-baseline gap-2 pt-6 self-start;
  }

  &__overall {
    @apply flex items-baseline gap-2 pb-6 self-start;
  }

  &__desc {
    font-size: 2rem;
    color: #011e5b;
    font-family: 'Futura-Bold';
    @apply mt-4;
    text-align: center;
  }

  &__submit {
    font-family: 'Futura-Bold';
    color: $action;
    background: linear-gradient(0deg, rgba(236, 139, 103, 1) 0%, rgba(252, 199, 60, 1) 100%);
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    @apply text-3xl py-2 px-4 mt-4;
  }
}