.end-activity {
  @apply flex h-full w-full pt-10 px-28 text-xl;

  h1 {
    color: $primaryBlue;
    @apply text-center text-5xl mt-6;
    font-family: 'Futura-Bold';
  }

  h2 {
    font-family: 'Futura-Bold';
    @apply mt-4;
  }

  p {
    font-family: 'Futura-Medium';
    @apply mt-6;
  }

  &__throphy {
    @apply w-16 my-6;
  }

  &__points {
    @apply flex flex-col justify-between text-center px-6 border-r-2 border-gray-500;
  }

  &__rank {
    @apply flex flex-col justify-between text-center px-6;
  }

  &__signup {
    @apply text-2xl self-start;
  }

  &__content {
    overflow-y: auto;
    margin: auto 0;
    max-height: calc(100vh - $headerHeight - 2.5rem - $footerHeight);
    @apply flex flex-col items-center bg-white flex-1 pb-6 px-8;
    background-color: #ffffff;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &__scores {
    background: $primaryBlue;
    @apply flex p-2 rounded-xl text-white uppercase;

    span {
      @apply text-5xl;
      color: #d4af37;
    }
  }

  &__data {
    font-family: 'Futura-Bold';
  }

  &__qr {
    color: $primaryPink;
    font-family: 'Futura-Bold';
    @apply flex mt-6 text-4xl px-4 items-center;
    margin-bottom: 1rem;
    padding-left: 0;
  }

  &__connected {
    color: $primaryPink;
    @apply px-2 cursor-pointer;
  }
}
.end-activity__content {
  background-color: #ffffff;
}
.end-activity__scores {
    background-color: #001f5b;
    color: #ffffff;
}
.end-activity__points, .end-activity__rank {
  color: #ffffff;
}
.scan-text {
    padding-left: 1.5rem;
}
.end-activity h2 {
  margin-bottom: 1rem;
}
