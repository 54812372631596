.question {
  transition: 0.2s all;
  @apply flex flex-col items-center;

  h3 {
    color: #57585b;
    @apply text-2xl;
    font-family: 'Futura-Medium';
  }

  h3,
  span {
    @apply self-start;
  }

  &__selected {
    transform: scale(0.985);
    background: #f9d2f4;
    background-color: #f9d2f4;
  }

  &__current {
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    @apply px-4 py-3 flex justify-between items-center text-xl mt-2;
  }

  &__correct {
    @apply text-xl py-4 mx-8;
  }

  &__fact {
    background: $primaryBlue;
    @apply text-xl mx-4 p-4;
    color: #ffffff;
  }

  &__submit {
    font-family: 'Futura-Bold';
    color: $action;
    background: linear-gradient(0deg, rgba(236, 139, 103, 1) 0%, rgba(252, 199, 60, 1) 100%);
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    @apply text-3xl mt-6 py-2 px-4;
  }

  &__source {
    @apply text-sm mx-8 py-4;
  }

  &__footnote {
    font-weight: 300;
    @apply text-sm mx-8 pt-4;
  }

  &__title {
    @apply text-xl;
  }

  &__answers {
    @apply flex flex-col text-xl pt-6 px-8 w-full;

    &_answer {
      border: 3px solid $primaryBlue;
      border-top-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      @apply p-4 transition-all;
      padding: .6rem 1rem;
      margin-bottom: 20px;
    }
  }
}


.question-answered {
  @apply w-full self-start;

  &__header {
    @apply self-start pt-4 py-8 w-full border-b-4 border-gray-300 px-8;
  }

  &__wrong {
    @apply border-2 border-red-500;
    border-color: #f04444;
  }

  &__right {
    @apply border-2 border-green-500;
    border-color: #23c45e;
  }

  &__text-right {
    @apply text-green-500 text-3xl;
    color: #23c45e;
  }

  &__text-wrong {
    @apply text-red-500 text-3xl;
    color: #f04444;
  }
}
