.layout {
  @apply max-h-screen h-screen w-full flex flex-col;

  &__wrapper {
    @apply flex flex-1;
  }

  &__content {
    width: 74%;
    max-height: calc(100vh - $headerHeight - $footerHeight);
  }

  &__sidebar {
    border-left: 2px solid $primaryBlue;
    width: 26%;
    min-width: 26%;
    max-height: calc(100vh - $headerHeight - $footerHeight);
    @apply bg-white z-20 overflow-y-auto;
  }
}
