@keyframes scales {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

.button {
  &__hover {
    &:hover {
      transform: scale(1.02);
    }
  }

  &__default {
    @apply text-3xl;
  }

  &__content {
    @apply z-10;
  }

  &__animation {
    animation: scales 2s linear infinite;
  }

  &__action {
    font-family: 'Futura-Bold';
    color: $action;
    background: linear-gradient(0deg, rgba(236, 139, 103, 1) 0%, rgba(252, 199, 60, 1) 100%);
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    @apply flex items-center justify-center cursor-pointer mt-4 self-center transition-all py-2 px-4;

    &:disabled {
      opacity: 0.7;
    }
  }
}
