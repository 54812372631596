@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/fonts.scss';
@import './styles/colors.scss';
@import './styles/variables.scss';

@layer components {
  @import './components/Header/Header.scss';
  @import './components/Footer/Footer.scss';
  @import './components/Sidebar/Sidebar.scss';
  @import './components/Categories/Categories.scss';
  @import './components/Modal/Modal.scss';
  @import './components/Question/Question.scss';
  @import './components/Modals/Modals.scss';
  @import './components/Button/Button.scss';
  @import './components/Leaderboard/Leaderboard.scss';
  @import './components/LeftSidebar/LeftSidebar.scss';
  @import './components/Layout/Layout.scss';
  @import './components/EndActivity/EndActivity.scss';
  @import './components/Spinner/Spinner.scss';
  @import './components/Input/MainInput.scss';
  @import './pages/Activity/Activity.scss';

  @import './components/BeginActivity/BeginActivity.scss';

}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  font-family: 'Futura-Book-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: lightgrey;
}

* {
  box-sizing: border-box;
}

.sup {
  vertical-align: super;
}
