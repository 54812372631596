.sidebar {
  @apply w-full bg-white z-10 p-6 text-lg;
  color: $primaryBlue;

  h1 {
    font-family: 'Futura-Bold';
    font-size: 2rem;
    line-height: 1.3;
    margin-bottom: 1rem;
  }

  &__link b {
    color: $secondaryPink;
    text-decoration: underline;
    text-decoration-color: $secondaryPink;
    @apply cursor-pointer ml-1;
  }

  &__list {
    @apply list-disc pl-5 text-lg mb-6;
  }
}
