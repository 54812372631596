$primaryBlue: #001f5b;
$white: #ffffff;
$secondaryBlue: #192e62;
$primaryPink: #cc27b0;
$yellow: #ffc647;
$dark: #231f20;
$gray: #58595b;
$secondaryPink: #bb3f97;
$lightDark: #262626;
$action: #231f20;
$gray: #bbbec0;
