.info-modal {
  @apply flex flex-col gap-4 text-xl p-12 w-[800px] bg-white rounded-2xl;
  background-color: #ffffff;

  &__header {
    color: $primaryBlue;
    font-family: 'Futura-Bold';
    font-size: 2rem;
    line-height: 1.3;
  }

  span > b {
    cursor: pointer;
    color: $secondaryPink;
    text-decoration: underline;
    text-decoration-color: $secondaryPink;
  }
}

.resume-modal {
  @apply bg-white w-[28rem] h-56 p-12 rounded-2xl flex flex-col items-center justify-between text-2xl;
  background: $primaryBlue;
  color: $yellow;

  b {
    font-family: 'Futura-Bold';
    @apply uppercase text-4xl text-black;
  }
}

.contact-modal {
  background: url('../../assets/ui/gradient-2.png'), $primaryBlue;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  @apply relative flex flex-col z-10 pt-14 px-16 gap-5;

  &__form {
    @apply flex flex-col gap-2 text-lg z-10 w-full mt-2 pb-10 text-white;

    input {
      border: 1px solid black;
    }
  }

  h1,
  h2 {
    @apply text-white;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  label {
    @apply text-white text-2xl;
  }

  input {
    @apply p-2 rounded text-black outline-0;
  }

  &__title {
    @apply info-modal__header text-white pb-4;
  }

  &__checkboxes {
    @apply flex gap-2 items-center text-xl;

    input {
      @apply w-4 h-4;
    }
  }

  &__footer {
    @apply text-white text-center text-xl;
  }

  @apply w-[800px] rounded-xl shadow-lg;
}
h1.contact-modal__title, .contact-modal h2, .contact-modal label, .contact-modal__checkboxes, .contact-modal {
  color: #ffffff;
}