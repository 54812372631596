.activity {
  background: url('../../assets/ui/gradient-artwork.png'), $primaryBlue;
  background-size: cover;
  background-position: bottom;
  @apply flex flex-row relative;

  &__rayaldee-logo {
    @apply h-48 py-8 self-start;
  }

  &__opko-logo {
    @apply absolute w-48 bottom-8 right-8;
  }

  &__bg-blue {
    background: $primaryBlue;
  }

  &__content {
    max-height: calc(100vh - $headerHeight - $footerHeight);
    @apply flex-1 overflow-y-auto flex items-center justify-center relative;
  }

  &__sidebar {
    height: calc(100vh - $headerHeight - $footerHeight);
    width: 27%;
    margin-left: 40px;
    @apply z-20 flex flex-col;
  }

  &__categories {
    background: $primaryBlue;
    @apply flex flex-col justify-center  w-full h-full px-16 pt-6 pb-0;
  }
}
