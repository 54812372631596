.begin-activity {
  @apply flex flex-col m-auto items-center justify-center p-12 h-full text-white text-center relative;

  form {
    max-width: 500px;
    @apply flex flex-col gap-4 mt-4 w-full;
  }

  h1 {
    color: $yellow;
    font-family: 'Futura-Bold';
    @apply pb-2 text-5xl text-center;
  }

  p {
    @apply text-2xl;
    color: #ffffff;
  }

  label {
    font-family: 'Futura-Bold';
    @apply text-left text-xl capitalize;
    color: #ffffff;
  }

  input {
    @apply p-4 text-black text-lg outline-0;
  }

  &__start {
    @apply uppercase text-5xl;
  }

  datalist {
    @apply z-10;
  }
}
p.text-white.req-start {
    font-size: 1.1rem;
    text-align: left;
  }
