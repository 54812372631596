@font-face {
  font-family: 'Futura-Bold';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('../assets/fonts/FuturaPT/FuturaPT-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Futura-Medium';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('../assets/fonts/FuturaPT/FuturaPT-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Futura-Regular';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../assets/fonts/FuturaPT/FuturaPT-BoldObl.woff') format('woff');
}

@font-face {
  font-family: 'Futura-Book-Regular';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../assets/fonts/FuturaPT/FuturaPT-Book.woff') format('woff');
}

@font-face {
  font-family: 'Futura-Demi';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../assets/fonts/FuturaPT/FuturaPT-Demi.woff') format('woff');
}
