.modal {
  background-color: rgba(0, 0, 0, 0.5);
  @apply fixed inset-0 flex items-center justify-center z-50;

  &__content {
    max-width: 75%;
    @apply relative shadow-lg rounded flex flex-col items-start text-gray-800;
    background-color: #ffffff;
    border-radius: 2rem;
  }

  &__close {
    @apply bg-gray-300 text-black absolute top-10 right-10 font-bold self-end rounded-full mb-3  w-8 h-8 z-20 opacity-40 hover:opacity-100 transition-all text-xl;
    background-color: #d1d5db;
    color: #000000;
  }
}
